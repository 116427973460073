/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //secondary navgation open drawer
        $('.nav-secondary>div>ul>li>a.dropdown-toggle').on('click',function(e){
          $('.nav-secondary').toggleClass('move-left');
        });
        //secondary navgation close drawer
        $('.close-dropdown').on('click',function(e){
          $(document).trigger('click.bs.dropdown.data-api');
          return false;
        });
        //secondary navgation close drawer when clicking on a link in the drawer
        $(document).on('click.bs.dropdown.data-api',function(){
          $('.nav-secondary').removeClass('move-left');
        });

        //the burger menu - open
        $('button.navbar-toggle').on('click', function(e){
          console.log('show sm menu');
          $('body').addClass('show-menu');
          return false;
        });
        //the burger menu - close
        $('#burger-menu .close-menu').on('click', function(){
          $('body').removeClass('show-menu');
        });
        //setup the map
        var $map = $('#usmap');
        if($map){
          var curState = $map.attr('data-state');
          var $mapCurState = $map.find('#'+curState+'-state');
          $mapCurState.attr('class',$mapCurState.attr('class')+' current');
          var servicable = $.parseJSON($map.attr('data-servicable'));
          $(servicable).each(function(){
            var $state = $('#'+this+'-state');
            $state.attr('class',$state.attr('class')+" servicable");
            $state.on('click',function(e){
              e.preventDefault();
              window.location.href = window.location.pathname+"?"+$.param({'state':$state.attr('id').substring(0,2)});
              return false;
            });
          });
        }

        //tabs
        $('.nav-tabs li a').click(function(e){
          e.preventDefault();
          e.stopImmediatePropagation();
          $(this).tab('show');
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function(){
        var slideshow = function(){
          var $slides = $('.banner-list li');
          if($slides.length > 1){
            var $curSlide = $slides.index($('.banner-list li.active'));
            var $nextSlide = $curSlide+1;
            if(($nextSlide+1)>$slides.length){
              $nextSlide = 0;
            }
            $('.banner-list li.active').removeClass('active');
            $('.banner-list li:eq('+$nextSlide+')').addClass('active');
          }
        };
        setInterval(slideshow,5000);
      }
    },
    // Local Page
    'local': {
      init: function() {
        // JavaScript to be fired on the local page
        //local tabs
        $('.page.local .wrap .nav-pills li a').click(function(e){
          e.preventDefault();
          e.stopImmediatePropagation();
          $(this).tab('show');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
